// ** React Imports
import {Fragment, useContext} from 'react'

// ** Custom Components
import NavbarUser from './NavbarUser'

// ** Third Party Components
import {Sun, Moon, Menu, Power} from 'react-feather'

// ** Reactstrap Imports
import {NavItem, NavLink, Alert, Badge} from 'reactstrap'

import {
  loggedUserAtom,
  mqttConnAtom,
  isMaintenanceBlockAtom,
  isRestartingAtom,
  versionAtom,
  isLoadingAtom,
  statusGetAtom
} from '@src/utility/atoms'
import {useAtom, useSetAtom} from 'jotai'
import {useResetAtom} from 'jotai/utils'
import UILoader from '@components/ui-loader'

import UserDropdown from './UserDropdown'
import Utility from '@src/utility/utility'
import {ThemeColors} from '@src/utility/context/ThemeColors'

import Alpha from '@src/views/Alpha'

const ThemeNavbar = props => {
  // ** Props
  const {skin, setSkin, setMenuVisibility} = props

  const resetLoginDoLogoff = useResetAtom(loggedUserAtom)
  const resetVersion = useResetAtom(versionAtom)
  const setIsLoading = useSetAtom(isLoadingAtom)

  const [mqttConnectionObj] = useAtom(mqttConnAtom)

  const [isMaintenanceBlocked] = useAtom(isMaintenanceBlockAtom)

  const [isRestarting] = useAtom(isRestartingAtom)

  const [versionMsg] = useAtom(versionAtom)

  const [statusGet] = useAtom(statusGetAtom)

  const {colors} = useContext(ThemeColors)

  //TODO fix: use darkModeAtom

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === 'dark') {
      return <Sun className="ficon" onClick={() => setSkin('light')} />
    } else {
      return <Moon className="ficon" onClick={() => setSkin('dark')} />
    }
  }

  console.log(statusGet)

  return (
    <Fragment>
      <ul className="navbar-nav d-xl-none d-flex align-items-center">
        <UILoader blocking={isRestarting} loader={<div />}>
          <NavItem className="mobile-menu me-auto">
            <NavLink
              className="nav-menu-main menu-toggle hidden-xs is-active"
              onClick={() => setMenuVisibility(true)}>
              <Menu className="ficon" />
            </NavLink>
          </NavItem>
        </UILoader>
      </ul>

      <ul className="navbar-nav d-flex align-items-center">
        <NavItem className="mt-1 ms-1">
          <div class="row">
            <UILoader
              blocking={isRestarting}
              loader={<div />}
              className="nav navbar-nav align-items-center ms-auto">
              <div class="col-md-12">
                <h4 className={isMaintenanceBlocked ? 'text-danger' : ''}>
                  {statusGet?.status?.varboxname} (
                  {statusGet?.status?.idgescovb})
                  <a
                    href="#"
                    onClick={() => {
                      const errMsg =
                        'Alcune funzioni sono disponibili solo con abbonamento Ubiway+.'
                      Utility.displayError(errMsg, colors)
                    }}>
                    *
                  </a>
                  {isMaintenanceBlocked && (
                    <a href="#/maintenance/block">
                      <Badge color="danger" pill className="badge-glow ms-1">
                        Impianto in blocco
                      </Badge>
                    </a>
                  )}
                  {statusGet?.status?.alarmisexp && (
                    <Badge color="danger" pill className="badge-glow ms-1">
                      Ubiway Più non attivo
                    </Badge>
                  )}
                </h4>
                {versionMsg.islockedb && (
                  <h4 className={isMaintenanceBlocked ? 'text-danger' : ''}>
                    <Badge color="danger" pill className="badge-glow ms-1">
                      Varbox Non configurato Apprendere con App Gesco Ubiway.
                      Tasto (+) VARBOX VB1
                    </Badge>
                  </h4>
                )}
              </div>
            </UILoader>
          </div>
        </NavItem>
      </ul>

      <ul className="nav navbar-nav align-items-center ms-auto">
        <UILoader
          blocking={isRestarting}
          loader={<div />}
          className="nav navbar-nav align-items-center ms-auto">
          <NavItem className="d-none d-lg-block">
            <NavLink className="nav-link-style">
              <ThemeToggler />
            </NavLink>
          </NavItem>
          <NavItem className="d-none d-lg-block">
            <NavLink className="nav-link-style">
              <Power
                className="ficon"
                onClick={() => {
                  localStorage.removeItem('userData')
                  resetLoginDoLogoff()
                  resetVersion()
                  setIsLoading(true)
                }}
              />
            </NavLink>
          </NavItem>
        </UILoader>
        <UserDropdown />
      </ul>
    </Fragment>
  )
}

export default ThemeNavbar
